@import '~antd/dist/antd.css';

.layout {
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #222;
}

.content {
  background: white;
  width: min-content;
  height: min-content;
  padding: 16px;
  width: 350px;
  border-radius: 3px;
  flex: 0;
}

.divLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2em;
  margin: 0 0 10px 0;
  text-align: center;
}

.imgLogo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.btnSubmit {
  width: 100%;
  height: 50px;
}

.itemForm {
  margin-bottom: 5px;
}