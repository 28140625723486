.LoginPage_layout__Be94a {
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #222;
}

.LoginPage_content__3-gkH {
  background: white;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 16px;
  width: 350px;
  border-radius: 3px;
  flex: 0 1;
}

.LoginPage_divLogo__3w5hJ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2em;
  margin: 0 0 10px 0;
  text-align: center;
}

.LoginPage_imgLogo__1py3O {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.LoginPage_btnSubmit__pbSJ9 {
  width: 100%;
  height: 50px;
}

.LoginPage_itemForm__2Bm0N {
  margin-bottom: 5px;
}
.MainLayout_logo__2v1kO {
  float: left;
  margin-right: 64px;
}

.MainLayout_logo__2v1kO .MainLayout_avatar__3KUGD{
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 16px;
  margin-right: 16px;
}

.MainLayout_logo__2v1kO .MainLayout_title__1XP9G{
  color: white;
  float: left;
  font-size: 20px;
  height: 32px;
}

.MainLayout_viewUserInfo__14FAe {
  float: right;
  flex-direction: row;
}

.MainLayout_avatar__3KUGD {
  margin-right: 10px;
}

.MainLayout_userName__3nP9k {
  float: right;
}

.MainLayout_breadcrumb__3jqTR {
  margin: 16px 0
}

.MainLayout_breadcrumbIcon__2Wdyx {
  margin-right: 3px;
}

.MainLayout_content__2Pslf {
  padding: 10px;
  height: 100%;
  background: white;
}
.nested-table {
  margin-top:10px;
}

.nested-table .ant-table-expanded-row > td:last-child {
  padding: 0 48px 0 8px;
}

.nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th {
  border-bottom: 1px solid #e9e9e9;
}

.nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
  padding-left: 0;
}

.nested-table .ant-table-expanded-row > td:last-child .ant-table-row td:first-child {
  padding-left: 0;
}

.nested-table .ant-table-expanded-row .ant-table-row:last-child td {
  border: none;
}

.nested-table .ant-table-expanded-row .ant-table-thead > tr > th {
  background: none;
}

.nested-table .nested-table-operation a:not(:last-child) {
  margin-right: 24px;
}

.nested-table .ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
.MultiModelSelect_select__P8zMP {
  margin-top: 10px;
  margin-bottom: 10px; 
  width: 100%;
}
.PhotoUploadPage_input__Pbrpw {
  margin-top: 10px;
  margin-bottom: 10px;
}

.PhotoUploadPage_modalButton__1JXA5 {
  margin-bottom: 10px;
}
