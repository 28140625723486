@import '~antd/dist/antd.css';

.logo {
  float: left;
  margin-right: 64px;
}

.logo .avatar{
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 16px;
  margin-right: 16px;
}

.logo .title{
  color: white;
  float: left;
  font-size: 20px;
  height: 32px;
}

.viewUserInfo {
  float: right;
  flex-direction: row;
}

.avatar {
  margin-right: 10px;
}

.userName {
  float: right;
}

.breadcrumb {
  margin: 16px 0
}

.breadcrumbIcon {
  margin-right: 3px;
}

.content {
  padding: 10px;
  height: 100%;
  background: white;
}