:global(.nested-table) {
  margin-top:10px;
}

:global(.nested-table .ant-table-expanded-row > td:last-child) {
  padding: 0 48px 0 8px;
}

:global(.nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th) {
  border-bottom: 1px solid #e9e9e9;
}

:global(.nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child) {
  padding-left: 0;
}

:global(.nested-table .ant-table-expanded-row > td:last-child .ant-table-row td:first-child) {
  padding-left: 0;
}

:global(.nested-table .ant-table-expanded-row .ant-table-row:last-child td) {
  border: none;
}

:global(.nested-table .ant-table-expanded-row .ant-table-thead > tr > th) {
  background: none;
}

:global(.nested-table .nested-table-operation a:not(:last-child)) {
  margin-right: 24px;
}

:global(.nested-table .ant-table-expanded-row:hover > td) {
  background: #fbfbfb;
}